import { format } from 'date-fns'
import { Capacitor } from '@capacitor/core'
import { Geolocation as CapacitorGeolocation } from '@capacitor/geolocation'
import { ProductSearchResult } from 'types/treatments'

export function findDispenseTime(
  quantity: number,
  fulfilment_windows: ProductSearchResult['fulfilment_windows']
) {
  let closestWindow = null

  fulfilment_windows.forEach((window) => {
    if (quantity >= window.from && quantity <= window.to) {
      closestWindow = window
    }
  })
  if (!closestWindow) {
    closestWindow = fulfilment_windows.reduce((a, b) => {
      return b.to < quantity && b.to > a.to ? b : a
    }, fulfilment_windows[0])
  }

  return closestWindow
}

export const isWebPlatform = () => {
  return Capacitor.getPlatform() === 'web'
}

export function getAge(date?: string) {
  return date && Math.floor((+new Date() - new Date(date).getTime()) / 3.15576e10)
}

export function formatCurrency(value: number) {
  return new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN',
  }).format(value / 100)
}

export function formatDate(date: string) {
  return format(new Date(date), 'dd/MM/yyyy')
}

export function formatTime(date?: string) {
  return new Date(date as string).toLocaleTimeString([], {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  })
}

export function addThousandSeparator(value: number | string, fractionalDigits = 2) {
  return Number(Number(value).toFixed(fractionalDigits)).toLocaleString('en', {
    minimumFractionDigits: fractionalDigits,
    maximumFractionDigits: fractionalDigits,
  })
}

export function convertKoboToNaira(value: number) {
  return Number(value) / 100
}

export const stripInputOffCommas = (value: string) => value?.replace(/,/g, '')

const isInputDecimal = (value: string) => {
  let numberBeforeDecimal = ''
  let numberAfterDecimal = ''
  let hasDecimal = false

  const strippedInput = stripInputOffCommas(value)

  // Check if input has decimal places and get the number before and after the decimal place
  if (strippedInput?.indexOf('.') > 0) {
    numberBeforeDecimal = strippedInput.split('.')[0]
    numberAfterDecimal = strippedInput.split('.')[1]
    hasDecimal = true
  } else {
    numberBeforeDecimal = strippedInput
  }

  return { numberBeforeDecimal, numberAfterDecimal, hasDecimal }
}

export default function formatCurrencyInput(inputValue: string) {
  if (typeof inputValue === 'undefined') {
    return '0.00'
  }

  let formattedInput = ''
  const { numberBeforeDecimal, numberAfterDecimal, hasDecimal } = isInputDecimal(inputValue)

  if (numberBeforeDecimal?.length < 4) {
    if (numberAfterDecimal) {
      formattedInput = numberBeforeDecimal + `.${numberAfterDecimal}`
    } else if (numberAfterDecimal === '' && hasDecimal) {
      formattedInput = numberBeforeDecimal + '.'
    } else {
      formattedInput = numberBeforeDecimal
    }
  }

  if (numberBeforeDecimal?.length === 4) {
    if (numberAfterDecimal) {
      formattedInput =
        numberBeforeDecimal[0] + ',' + numberBeforeDecimal?.substring(1) + `.${numberAfterDecimal}`
    } else if (numberAfterDecimal === '' && hasDecimal) {
      formattedInput = numberBeforeDecimal[0] + ',' + numberBeforeDecimal?.substring(1) + '.'
    } else {
      formattedInput = numberBeforeDecimal[0] + ',' + numberBeforeDecimal?.substring(1)
    }
  }

  if (numberBeforeDecimal?.length === 5) {
    if (numberAfterDecimal) {
      formattedInput =
        numberBeforeDecimal[0] +
        numberBeforeDecimal[1] +
        ',' +
        numberBeforeDecimal?.substring(2) +
        `.${numberAfterDecimal}`
    } else if (numberAfterDecimal === '' && hasDecimal) {
      formattedInput = formattedInput =
        numberBeforeDecimal[0] +
        numberBeforeDecimal[1] +
        ',' +
        numberBeforeDecimal?.substring(2) +
        '.'
    } else {
      formattedInput =
        numberBeforeDecimal[0] + numberBeforeDecimal[1] + ',' + numberBeforeDecimal?.substring(2)
    }
  }

  if (numberBeforeDecimal?.length === 6) {
    if (numberAfterDecimal) {
      formattedInput =
        numberBeforeDecimal[0] +
        numberBeforeDecimal[1] +
        numberBeforeDecimal[2] +
        ',' +
        numberBeforeDecimal?.substring(3) +
        `.${numberAfterDecimal}`
    } else if (numberAfterDecimal === '' && hasDecimal) {
      formattedInput =
        numberBeforeDecimal[0] +
        numberBeforeDecimal[1] +
        numberBeforeDecimal[2] +
        ',' +
        numberBeforeDecimal?.substring(3) +
        '.'
    } else {
      formattedInput =
        numberBeforeDecimal[0] +
        numberBeforeDecimal[1] +
        numberBeforeDecimal[2] +
        ',' +
        numberBeforeDecimal?.substring(3)
    }
  }

  if (numberBeforeDecimal?.length === 7) {
    if (numberAfterDecimal) {
      formattedInput =
        numberBeforeDecimal[0] +
        ',' +
        numberBeforeDecimal[1] +
        numberBeforeDecimal[2] +
        numberBeforeDecimal[3] +
        ',' +
        numberBeforeDecimal?.substring(4) +
        `.${numberAfterDecimal}`
    } else if (numberAfterDecimal === '' && hasDecimal) {
      formattedInput =
        numberBeforeDecimal[0] +
        ',' +
        numberBeforeDecimal[1] +
        numberBeforeDecimal[2] +
        numberBeforeDecimal[3] +
        ',' +
        numberBeforeDecimal?.substring(4) +
        '.'
    } else {
      formattedInput =
        numberBeforeDecimal[0] +
        ',' +
        numberBeforeDecimal[1] +
        numberBeforeDecimal[2] +
        numberBeforeDecimal[3] +
        ',' +
        numberBeforeDecimal?.substring(4)
    }
  }

  if (numberBeforeDecimal?.length === 8) {
    if (numberAfterDecimal) {
      formattedInput =
        numberBeforeDecimal[0] +
        numberBeforeDecimal[1] +
        ',' +
        numberBeforeDecimal[2] +
        numberBeforeDecimal[3] +
        numberBeforeDecimal[4] +
        ',' +
        numberBeforeDecimal?.substring(5) +
        `.${numberAfterDecimal}`
    } else if (numberAfterDecimal === '' && hasDecimal) {
      formattedInput = formattedInput =
        numberBeforeDecimal[0] +
        numberBeforeDecimal[1] +
        ',' +
        numberBeforeDecimal[2] +
        numberBeforeDecimal[3] +
        numberBeforeDecimal[4] +
        ',' +
        numberBeforeDecimal?.substring(5) +
        '.'
    } else {
      formattedInput =
        numberBeforeDecimal[0] +
        numberBeforeDecimal[1] +
        ',' +
        numberBeforeDecimal[2] +
        numberBeforeDecimal[3] +
        numberBeforeDecimal[4] +
        ',' +
        numberBeforeDecimal?.substring(5)
    }
  }

  if (numberBeforeDecimal?.length === 9) {
    if (numberAfterDecimal) {
      formattedInput =
        numberBeforeDecimal[0] +
        numberBeforeDecimal[1] +
        numberBeforeDecimal[2] +
        ',' +
        numberBeforeDecimal[3] +
        numberBeforeDecimal[4] +
        numberBeforeDecimal[5] +
        ',' +
        numberBeforeDecimal?.substring(6) +
        `.${numberAfterDecimal}`
    } else if (numberAfterDecimal === '' && hasDecimal) {
      formattedInput =
        numberBeforeDecimal[0] +
        numberBeforeDecimal[1] +
        numberBeforeDecimal[2] +
        ',' +
        numberBeforeDecimal[3] +
        numberBeforeDecimal[4] +
        numberBeforeDecimal[5] +
        ',' +
        numberBeforeDecimal?.substring(6) +
        '.'
    } else {
      formattedInput =
        numberBeforeDecimal[0] +
        numberBeforeDecimal[1] +
        numberBeforeDecimal[2] +
        ',' +
        numberBeforeDecimal[3] +
        numberBeforeDecimal[4] +
        numberBeforeDecimal[5] +
        ',' +
        numberBeforeDecimal?.substring(6)
    }
  }

  if (numberBeforeDecimal?.length === 10) {
    if (numberAfterDecimal) {
      formattedInput =
        numberBeforeDecimal[0] +
        ',' +
        numberBeforeDecimal[1] +
        numberBeforeDecimal[2] +
        numberBeforeDecimal[3] +
        ',' +
        numberBeforeDecimal[4] +
        numberBeforeDecimal[5] +
        numberBeforeDecimal[6] +
        ',' +
        numberBeforeDecimal?.substring(7) +
        `.${numberAfterDecimal}`
    } else if (numberAfterDecimal === '' && hasDecimal) {
      formattedInput =
        numberBeforeDecimal[0] +
        ',' +
        numberBeforeDecimal[1] +
        numberBeforeDecimal[2] +
        numberBeforeDecimal[3] +
        ',' +
        numberBeforeDecimal[4] +
        numberBeforeDecimal[5] +
        numberBeforeDecimal[6] +
        ',' +
        numberBeforeDecimal?.substring(7) +
        '.'
    } else {
      formattedInput =
        numberBeforeDecimal[0] +
        ',' +
        numberBeforeDecimal[1] +
        numberBeforeDecimal[2] +
        numberBeforeDecimal[3] +
        ',' +
        numberBeforeDecimal[4] +
        numberBeforeDecimal[5] +
        numberBeforeDecimal[6] +
        ',' +
        numberBeforeDecimal?.substring(7)
    }
  }

  return formattedInput
}

export function printDiv() {
  window.print()
}

export function combineDateAndTime(date: Date, time: Date) {
  const mins = ('0' + time.getMinutes()).slice(-2)
  const hours = ('0' + time.getHours()).slice(-2)
  const timeString = hours + ':' + mins + ':00'
  const year = date.getFullYear()
  const month = ('0' + (date.getMonth() + 1)).slice(-2)
  const day = ('0' + date.getDate()).slice(-2)
  const dateString = '' + year + '-' + month + '-' + day
  const datec = dateString + 'T' + timeString
  return new Date(datec)
}

export async function requestLocation(
  next: (coords: { latitude: number; longitude: number }) => void
) {
  const isNative = Capacitor.isNativePlatform()

  if (isNative) {
    // Use Capacitor Geolocation for native platforms
    try {
      const permissionStatus = await CapacitorGeolocation.checkPermissions()

      if (permissionStatus.location !== 'granted') {
        await CapacitorGeolocation.requestPermissions()
      }

      const position = await CapacitorGeolocation.getCurrentPosition({
        enableHighAccuracy: true,
      })

      next({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      })
      localStorage.setItem('locationPermissionGranted', 'true')
    } catch (error) {
      localStorage.setItem('locationPermissionGranted', 'false')
    }
  } else {
    // Use Web Geolocation API for web platforms
    if (!('geolocation' in navigator)) {
      return
    }

    const options = {
      enableHighAccuracy: true,
      maximumAge: 0,
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        next({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        })
        localStorage.setItem('locationPermissionGranted', 'true')
      },
      () => {
        localStorage.setItem('locationPermissionGranted', 'false')
      },
      options
    )
  }
}

export function fetchItemFromStorage<T = any>(
  key: string,
  storeType: 'localStorage' | 'sessionStorage'
): T | undefined {
  if (storeType === 'localStorage') {
    if (!localStorage[key]) return
    return JSON.parse(localStorage.getItem(key) || '')
  } else {
    if (!sessionStorage[key]) return
    return JSON.parse(sessionStorage.getItem(key) || '')
  }
}

import { useEffect } from 'react'

const ZohoSalesIQScripts = (prop: { shouldLoadScript: boolean }) => {
  useEffect(() => {
    if (prop.shouldLoadScript) {
      const inlineScript = document.createElement('script')
      inlineScript.innerHTML = `
   window.$zoho=window.$zoho || {};
   $zoho.salesiq=$zoho.salesiq || {ready:function(){}}
    `
      document.head.appendChild(inlineScript)

      // Add the second script with the src for Zoho SalesIQ
      const scriptTag = document.createElement('script')
      scriptTag.id = 'zsiqscript'
      scriptTag.src =
        'https://salesiq.zohopublic.com/widget?wc=siq4dfac6b8f218e47bca918dd572c5bb2af9250694135ab4c8e9fd2b7f70cc4ab94eb72ebd716aa8c27762d4cb27e70a44'
      scriptTag.defer = true
      document.head.appendChild(scriptTag)
    }
  }, [])

  return null
}

export default ZohoSalesIQScripts

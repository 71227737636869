import AllRoutes from './routes'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { QueryClient, QueryClientProvider, QueryKey } from '@tanstack/react-query'
import { defaultQueryFn } from 'utils/api'
import { registerSafeAreaElement } from '@aashu-dubey/capacitor-statusbar-safe-area'
import { useEffect } from 'react'
import { PushNotifications } from '@capacitor/push-notifications'
import { Capacitor } from '@capacitor/core'
import ZohoSalesIQWidget from 'scripts/Zoho'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { ORDER_FLOW_KEY } from 'app-constants'

registerSafeAreaElement()

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
      cacheTime: 100 * 60 * 60, //1hour
    },
  },
})

function App() {
  useEffect(() => {
    async function performActiononPush() {
      const permStatus = await PushNotifications.checkPermissions()
      if (permStatus.receive === 'denied') {
        return
      }
      await PushNotifications.register()

      await PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
        if (import.meta.env.VITE_PUSH_SERVICE === 'pusher') {
          const targetType = notification?.notification?.data?.data.target_type
          const targetId = notification?.notification?.data?.data.target_id
          if (targetType === 'order') {
            window.location.href = `/orders/ongoing/${targetId}`
          }
        } else {
          const targetType = notification?.notification?.data?.target_type
          const targetId = notification?.notification?.data?.target_id
          if (targetType === 'order') {
            window.location.href = `/orders/ongoing/${targetId}`
          }
        }
      })
    }

    if (Capacitor.isNativePlatform()) {
      performActiononPush()
    }
  }, [])

  const persister = createSyncStoragePersister({
    storage: window.sessionStorage,
    key: 'EAZYMED_CACHE',
  })

  return (
    <>
      <ZohoSalesIQWidget shouldLoadScript={!Capacitor.isNativePlatform()} />
      <safe-area mode="margin" edges="top , bottom">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <PersistQueryClientProvider
            client={queryClient}
            persistOptions={{
              persister,
              dehydrateOptions: {
                shouldDehydrateQuery: (query: { queryKey: QueryKey }) => {
                  // Add condition to persist only orderFlowData
                  return [ORDER_FLOW_KEY].includes(query.queryKey[0] as string)
                },
              },
            }}
          >
            <QueryClientProvider client={queryClient}>
              <AllRoutes />
            </QueryClientProvider>
          </PersistQueryClientProvider>
        </LocalizationProvider>
      </safe-area>
    </>
  )
}

export default App

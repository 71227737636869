import { lazy } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { AppProvider } from 'context'
import NotFound from 'components/NotFound'
import Login from 'pages/login'
// import PaymentRequestDetails from 'components/orders/pending-orders/payment-requests/payment-request-details'
import MedicationDetails from 'pages/providers/clinics/clinc-detail/clinic-treatments/treatment-details/treatment-medications/medication-details'
import SetPassword from 'pages/login/set-password'
const CartDetails = lazy(() => import('components/orders/cart/cart-details'))
const OrderDetails = lazy(() => import('components/orders/order-details'))

const DeleteAccount = lazy(() => import('pages/profile/delete-account'))
const TrackOrder = lazy(() => import('pages/orders/track-order'))
const ReceiptDetails = lazy(
  () => import('pages/providers/clinics/clinc-detail/clinical-payments/receipts/receipt-details')
)
const Invoices = lazy(
  () => import('pages/providers/clinics/clinc-detail/clinical-payments/invoices')
)
const Receipts = lazy(
  () => import('pages/providers/clinics/clinc-detail/clinical-payments/receipts')
)
const ClinicAdmissions = lazy(
  () => import('pages/providers/clinics/clinc-detail/clinic-admissions')
)
const RequestList = lazy(
  () =>
    import(
      'pages/providers/clinics/clinc-detail/clinic-appointments/clinc-request-appointment/request-list'
    )
)
const ClinicScheduledAppointment = lazy(
  () =>
    import('pages/providers/clinics/clinc-detail/clinic-appointments/clinic-scheduled-appointment')
)
const ClinicHistory = lazy(
  () => import('pages/providers/clinics/clinc-detail/clinic-appointments/clinic-history')
)

const RefillHistory = lazy(
  () =>
    import(
      'pages/providers/clinics/clinc-detail/clinic-treatments/treatment-details/treatment-medications/refill-history'
    )
)

const TreatmentDetail = lazy(
  () => import('pages/providers/clinics/clinc-detail/clinic-treatments/treatment-details')
)
const ClinicRequestAppointment = lazy(
  () => import('pages/providers/clinics/clinc-detail/clinic-appointments/clinc-request-appointment')
)

const TreatmentAppointments = lazy(
  () =>
    import(
      'pages/providers/clinics/clinc-detail/clinic-treatments/treatment-details/treatment-appoinments'
    )
)
const TreatmentPrescriptions = lazy(
  () =>
    import(
      'pages/providers/clinics/clinc-detail/clinic-treatments/treatment-details/treatment-prescriptions'
    )
)
const TreatmentMedications = lazy(
  () =>
    import(
      'pages/providers/clinics/clinc-detail/clinic-treatments/treatment-details/treatment-medications'
    )
)
const Wallet = lazy(() => import('pages/wallet'))
const RequestNewAppointment = lazy(
  () =>
    import(
      'pages/providers/clinics/clinc-detail/clinic-appointments/clinic-history/request-new-appointment'
    )
)
const AdmissionDetail = lazy(
  () => import('pages/providers/clinics/clinc-detail/clinic-admissions/admission-detail')
)

const ClinicPayments = lazy(() => import('pages/providers/clinics/clinc-detail/clinical-payments'))
const InvoiceDetails = lazy(
  () => import('pages/providers/clinics/clinc-detail/clinical-payments/invoices/invoice-details')
)
// const OrderDetails = lazy(() => import('pages/orders/order-details'))

const ClinicTreatments = lazy(
  () => import('pages/providers/clinics/clinc-detail/clinic-treatments')
)

const Pharmacies = lazy(() => import('pages/providers/pharmacy'))

// const CompletedOrders = lazy(() => import('pages/orders/completed-orders'))

const Clinics = lazy(() => import('pages/providers/clinics'))
const ClinicDetail = lazy(() => import('pages/providers/clinics/clinc-detail'))
const OtherProviders = lazy(() => import('pages/providers/other-providers'))
const ClinicAppointments = lazy(
  () => import('pages/providers/clinics/clinc-detail/clinic-appointments')
)
const EditAppointment = lazy(
  () => import('pages/providers/clinics/clinc-detail/clinic-appointments/edit-appointment')
)
const AppointmentDetail = lazy(
  () =>
    import(
      'pages/providers/clinics/clinc-detail/clinic-appointments/clinic-history/appointment-details/AppointmentDetails'
    )
)
const ForgotPassword = lazy(() => import('pages/forgot-password'))
const EasyMed = lazy(() => import('pages/easy-med'))
const Home = lazy(() => import('pages/home'))
const Main = lazy(() => import('layout/main'))
const OnboardingLayout = lazy(() => import('layout/onboarding'))
const Register = lazy(() => import('pages/register'))
const Providers = lazy(() => import('pages/providers'))
const MyProviders = lazy(() => import('pages/providers/MyProviders'))
const ViewStatus = lazy(() => import('pages/orders/ViewStatus'))
const Profile = lazy(() => import('pages/profile'))
const Orders = lazy(() => import('pages/orders'))
const Notifications = lazy(() => import('pages/notifications'))
const PrescriptionConfirmation = lazy(() => import('pages/prescription/PrescriptionConfirmation'))

function AllRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<OnboardingLayout />}>
          <Route path="/login" element={<Login />}>
            <Route path="set-new-password" element={<SetPassword />} />
          </Route>
          <Route path="/signup" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
        </Route>

        <Route
          element={
            <AppProvider>
              <Main />
            </AppProvider>
          }
        >
          <Route path="/" element={<Home />} />

          <Route path="/orders" element={<Orders />}>
            <Route path="cart/:id" element={<CartDetails />} />

            <Route path="ongoing/:id" element={<OrderDetails />} />
            <Route path="completed/:id" element={<OrderDetails />} />
            <Route path="view-status" element={<ViewStatus />} />
          </Route>
          <Route path="track-order">
            <Route path=":id" element={<TrackOrder />} />
          </Route>
          <Route path="/easy-med" element={<EasyMed />} />

          <Route path="/providers" element={<Providers />}>
            <Route path="other-providers" element={<OtherProviders />} />
            <Route path="clinics" element={<Clinics />}>
              <Route path=":id" element={<ClinicDetail />}>
                <Route path="appointments" element={<ClinicAppointments />}>
                  <Route path="request-list" element={<RequestList />}>
                    <Route path="edit">
                      <Route path=":requestedAppointmentId" element={<EditAppointment />} />
                    </Route>
                  </Route>
                  <Route path="scheduled" element={<ClinicScheduledAppointment />} />
                  <Route path="history" element={<ClinicHistory />}>
                    <Route path=":appointmentId" element={<AppointmentDetail />} />
                    <Route path="request" element={<RequestNewAppointment />}>
                      <Route path=":requestedAppointmentId" element={<RequestNewAppointment />} />
                    </Route>
                  </Route>
                  <Route path="request" element={<ClinicRequestAppointment />} />

                  <Route path=":appointmentId" element={<AppointmentDetail />} />
                </Route>
                <Route path="admissions" element={<ClinicAdmissions />}>
                  <Route path=":admissionId" element={<AdmissionDetail />} />
                </Route>
                <Route path="payments" element={<ClinicPayments />}>
                  <Route path="invoice" element={<Invoices />}>
                    <Route path=":invoiceId" element={<InvoiceDetails />} />
                  </Route>
                  <Route path="receipt" element={<Receipts />}>
                    <Route path=":receiptId" element={<ReceiptDetails />} />
                  </Route>
                </Route>
                <Route path="treatments" element={<ClinicTreatments />}>
                  <Route path=":treatmentId" element={<TreatmentDetail />}>
                    <Route path="appointment" element={<TreatmentAppointments />} />
                    <Route path="prescription" element={<TreatmentPrescriptions />} />
                    <Route path="medication" element={<TreatmentMedications />}>
                      <Route path=":medicationId" element={<MedicationDetails />} />
                      <Route path=":medicationId/refill-history" element={<RefillHistory />} />
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route path="pharmacies" element={<Pharmacies />}>
              <Route path=":id">
                <Route path="pending-orders" element={<Orders />}>
                  <Route path="cart/:id" element={<CartDetails />} />

                  <Route path="ongoing/:id" element={<OrderDetails />} />
                  <Route path="completed/:id" element={<OrderDetails />} />
                </Route>
              </Route>
            </Route>

            <Route path="my-providers" element={<MyProviders />} />
            <Route path="other-providers" element={<OtherProviders />} />
          </Route>
          <Route path="/profile" element={<Profile />}>
            <Route path="delete" element={<DeleteAccount />} />
          </Route>

          <Route path="/notifications" element={<Notifications />} />
          <Route path="/wallet" element={<Wallet />} />
        </Route>

        <Route path="*" element={<NotFound />} />
        <Route path="confirm-request" element={<PrescriptionConfirmation />} />
      </Routes>
    </BrowserRouter>
  )
}
export default AllRoutes

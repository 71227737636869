/** @type {import('tailwindcss').Config} */
export default {
  content: ['./index.html', './src/**/*.{js,ts,jsx,tsx}'],
  important: true,
  theme: {
    extend: {
      colors: {
        brand: {
          50: '#E1F4FA',
          100: '#B5E1F3',
          200: '#87CEEB',
          300: '#5BBBE4',
          400: '#38AEE1',
          500: '#17A0DE',
          600: '#1293D0',
          700: '#0980BD',
          800: '#0370AA',
          900: '#005189',
        },
        success: {
          50: '#EDFCEB',
          100: '#D1F6CD',
          200: '#B2F0AC',
          300: '#90E989',
          400: '#71E36D',
          500: '#51DD51',
          600: '#40CC48',
          700: '#20B73E',
          800: '#00A233',
          900: '#007E1F',
        },
        neutral: {
          50: '#ECEDED',
          100: '#CED2D7',
          200: '#ADB5BD',
          300: '#8C98A4',
          400: '#748291',
          500: '#5C6E7F',
          600: '#50606F',
          700: '#404D59',
          800: '#313B44',
          900: '#20272D',
        },
        clinic: {
          50: '#F8EAEC',
          100: '#EECCCF',
          200: '#D59997',
          300: '#C27571',
          400: '#C75B52',
          500: '#C8503D',
          600: '#B9483B',
          700: '#A84035',
          800: '#9B3A2F',
          900: '#8D3125',
        },
        pharmacy: {
          50: '#DEF3F8',
          100: '#C0DDE0',
          200: '#A0C3C9',
          300: '#7FAAB1',
          400: '#66969F',
          500: '#4B838D',
          600: '#40747C',
          700: '#325F67',
          800: '#254C53',
          900: '#14373C',
        },
        error: {
          50: '#FEE7E7',
          100: '#FFC8BA',
          200: '#FFA38D',
          300: '#FF7B60',
          400: '#FF593D',
          500: '#FF2E1B',
          600: '#FF2617',
          700: '#F81B10',
          800: '#EA0A0A',
          900: '#D20000',
        },
        information: {
          50: '#EFE7FE',
          100: '#D3C4FC',
          200: '#B59DFC',
          300: '#9473FC',
          400: '#7753FB',
          500: '#5531F9',
          600: '#452CF3',
          700: '#2824EA',
          800: '#001EE5',
          900: '#000DDF',
        },
        warning: '#ECBB2E',
      },
      backgroundColor: {
        primary: '#c75b52',
        'primary-light': 'hsl(184, 47%, 93%)',
        light: '#fff8f5',
        dark: '#7A2D26',
        'fast-black': '#353A3F',
      },
      borderColor: {
        default: '#CED2D7',
      },
      boxShadow: {
        default: '5px 11px 40.43px rgba(211, 209, 216, 0.3)',
      },
      screens: {
        lgMax: { max: '1023px' },
        mdMax: { max: '767px' },
        smMax: { max: '639px' },
        xsMax: { max: '375px' },
      },
      borderRadius: {
        25: '25px',
      },
      fontSize: {
        'title-mobile': '1.125rem',
        'title-desktop': '1.75rem',
        'subtitle-mobile': '0.875rem',
        'subtitle-desktop': '1rem',
      },
      fontFamily: {
        raleway: ['Raleway', 'sans-serif'],
        sans: ['DM Sans', 'serif'],
      },
    },
  },
  plugins: [],
}

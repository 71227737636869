import { Outlet, useLocation, useNavigate, useMatch } from 'react-router-dom'
import { Formik } from 'formik'
import { Button, TextField, IconButton, Alert, CircularProgress } from '@mui/material'
import { login } from 'utils/api/auth'
import { Link } from 'react-router-dom'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { useState } from 'react'
import { APP_VERSION, REDIRECT_KEY, TIMEOUT_KEY, TOKEN_KEY } from 'app-constants'
import yup from 'utils/yup'
import { ReactComponent as Logo } from 'assets/images/fastlink.svg'
import { Storage } from '@capacitor/storage'
import { Capacitor } from '@capacitor/core'

const loginSchema = yup.object().shape({
  password: yup.string().required('Enter your password'),
  email: yup.string().email('enter a valid email').required('please enter email'),
})

function Login() {
  const navigate = useNavigate()
  const [isPasswordVisible, setPasswordVisibility] = useState(false)
  const [loginError, setLoginError] = useState('')
  const isPage = useMatch('/login')
  const location = useLocation()

  const redirectUrl =
    (location.state as any)?.redirect || sessionStorage.getItem(REDIRECT_KEY) || '/'

  function sendBroadCast(email: string) {
    const channel = new BroadcastChannel('loginChannel')
    channel.postMessage({
      currentLoggedInUser: JSON.stringify({ email }),
    })
    channel.close()
  }

  return (
    <div className="h-fit">
      <Outlet />
      {isPage && (
        <div className="lg:bg-white lg:shadow lg:p-10 p-5 lg:w-[35rem] lg:rounded-3xl">
          <div className="self-center grid place-items-center mb-12">
            <a href={import.meta.env.VITE_EAZYMED_WEBSITE_URL} target="_blank" rel="noreferrer">
              <Logo className="text-8xl" />
            </a>
          </div>
          <div className="mb-12 grid place-items-center">
            <h1 className="lg:text-center text-left title-mobile lg:title-desktop">
              Welcome Back 😃
            </h1>
            <p className=" lg:text-center text-left subtitle-mobile lg:subtitle-desktop text-neutral-600  mt-2">
              Log In to your account
            </p>
          </div>
          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={loginSchema}
            onSubmit={async (values) => {
              try {
                const {
                  token,
                  token_ttl,
                  user: { email, metadata, id },
                } = await login(values)
                sessionStorage.setItem(TOKEN_KEY, token)
                sessionStorage.setItem(TIMEOUT_KEY, token_ttl.toString())

                await Storage.set({
                  key: 'token',
                  value: token,
                })
                await Storage.set({
                  key: 'id',
                  value: id,
                })
                if (metadata.should_set_password) {
                  navigate('set-new-password', { state: { token } })
                  return
                } else {
                  sendBroadCast(email)
                  navigate(redirectUrl, { replace: true })
                }
              } catch (error: any) {
                setLoginError(error.message)
              }
            }}
          >
            {({ values, errors, touched, handleChange, handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit}>
                <p className="mb-2 text-sm font-semibold">Your email address</p>
                <TextField
                  id="email"
                  type="email"
                  className="mb-8"
                  value={values.email}
                  onChange={handleChange}
                  error={touched.email && !!errors.email}
                  helperText={touched.email ? errors.email : ''}
                  inputProps={{
                    className: 'rounded-[11px]',
                  }}
                  fullWidth
                />{' '}
                <p className="mb-2 text-sm font-semibold">Password</p>
                <TextField
                  id="password"
                  type={isPasswordVisible ? 'text' : 'password'}
                  className="border-red"
                  value={values.password}
                  onChange={handleChange}
                  error={touched.password && !!errors.password}
                  placeholder="Type your password"
                  helperText={touched.password ? errors.password : ''}
                  fullWidth
                  inputProps={{
                    className: 'rounded-[11px]',
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() => setPasswordVisibility(!isPasswordVisible)}
                        className="text-neutral-600"
                      >
                        {!isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                        <span className="sr-only">
                          {isPasswordVisible ? 'Hide Password' : 'Show Password'}
                        </span>
                      </IconButton>
                    ),
                  }}
                />
                <div className="flex justify-between items-center mt-3">
                  <Link to="/forgot-password" className="text-brand-700 font-medium text-xs">
                    Forgot Password
                  </Link>
                </div>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  className="mt-8 p-3 rounded-md"
                  fullWidth
                >
                  {isSubmitting ? (
                    <>
                      <CircularProgress color="inherit" className="text-white" size={22} />
                      <span className="sr-only">Submitting</span>
                    </>
                  ) : (
                    'LOG IN'
                  )}
                </Button>
                <Alert
                  severity="error"
                  className={`mt-4 ${!loginError ? 'opacity-0' : ''}`}
                  aria-hidden={!loginError}
                  data-test-id="login-server-error"
                >
                  {loginError}
                </Alert>
                <p className="mt-4 text-center text-sm font-medium text-neutral-500">
                  Don&apos;t have an account yet?&nbsp;
                  <Link to="/signup" className="text-brand-400">
                    Create Account
                  </Link>
                </p>
                {Capacitor.isNativePlatform() && (
                  <div className="text-center mt-10">
                    <span className='text-xs'>v</span>{`${APP_VERSION}`}
                  </div>
                )}
              </form>
            )}
          </Formik>
        </div>
      )}
    </div>
  )
}

export default Login
